import React, { useState, useEffect } from 'react';
import './MobilePortfolio.css';
import dale3 from '../../Assets/Images/Group-3014.png'
import google from '../../Assets/Images/Group-3058.png'
import apple from '../../Assets/Images/Group-3057.png'
import one2one from '../../Assets/Images/one2one-logo.png'
import reson8 from '../../Assets/Images/Reson8-logo.png'
import gpsfalogo from '../../Assets/Images/GPS-Fan-logo.png'
import tagoride from '../../Assets/Images/Group-3018.png'
import safelet from '../../Assets/Images/Group-3019.png'
import carmudi from '../../Assets/Images/Group-3020.png'
import upaid from '../../Assets/Images/Group-3023.png'

import { Link } from 'react-router-dom';
import dale3bg from '../../Assets/Images/Dale3.png'
import one2onebg from '../../Assets/Images/One2One_Bg.jpg'
import reson8bg from '../../Assets/Images/BG.jpg'
import gpsfalogobg from '../../Assets/Images/GPs-Fan-Bg-copy.jpg'
import tagoridebg from '../../Assets/Images/TangoRide.jpg'
import safeletbg from '../../Assets/Images/safelet.png'
import carmudibg from '../../Assets/Images/Car_Mudi.jpg'
import upaidbg from '../../Assets/Images/U_paid_M.png'
import viewallbutton from '../../Assets/Images/Group-3229.png'
import { useLocation } from 'react-router-dom';









const MobilePortfolioComp = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const location = useLocation();

  const list = [
    {
      name: "Dale3joa3ak (Food App)",
      desc:
        "Dale3 Jo3ak is a platform that helps you find and order food from wherever you are. Install the mobile application, type in an address; we list the restaurants that deliver to that location, other user’s ratings.",
      img:
        dale3bg,
      thumbImg:
        dale3, additionalImage1: google,
      additionalImage2: apple,
      additionalLink1: "https://play.google.com/store/apps/details?id=com.mobiwhiz.dale3jo3ak",
      additionalLink2: "https://apps.apple.com/in/app/dale3-jo3ak/id1504892575",
    },
    {
      name: "One2One(PLACEMENT TEST!)",
      desc:
        "🚀 Ready to Level Up Your English? Try the One2One PLACEMENT TEST! 🌟. AI-Powered English Insight!Hey there! Wondering where your English skills stand? We've got just the thing – the One2One Placement Test. It's quick, easy, and super insightful. In just 60 minutes, you'll know exactly how you're doing in speaking, writing, listening, and grammar. Here's the scoop.",
      img:
        one2onebg,
      thumbImg:
        one2one,
      additionalImage1: google,
      additionalImage2: apple,
      additionalLink1: "https://play.google.com/store/apps/details?id=app.mymoodai&hl=en-IN",  // Add this line
      //  additionalLink2: "" ,
    },
    {
      name: "Reson8(Music)",
      desc:
        "Reson8 Frequency Healing is a personalized sound healing app designed to enhance your physical and emotional well-being. Using advanced audio technology, Reson8 creates customized soundscapes that cater to your individual preferences and needs. Experience relaxation and mental clarity with our unique frequency-based music therapy, designed to promote overall wellness.",
      img:
        reson8bg,
      thumbImg:
        reson8,
      additionalImage1: google,
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
      additionalLink1: apple,  // Add this line
      // additionalLink2: "https://apps.apple.com/us/app/getaround-instant-car-rental/id412017926" ,
    },
    {
      name: "Gpsfan(Tracking)",
      desc:
        "GPSFanNew is the best GPS Tracking application in Morocco which allows you to manage your fleet, to minimize the costs generated by the distribution service.Our application allows you to track your vehicles using GPS. We offer an impeccable geolocation system and high quality GPS trackers.",
      img:
        gpsfalogobg,
      thumbImg:
        gpsfalogo,
      additionalImage1: google,
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
      additionalLink1: apple,  // Add this line
      // additionalLink2: "#" ,
    },
    {
      name: "Tangoride(Ride app)",
      desc:
        "Tangoride is a ridesharing and carpooling app, but it’s also intended to be a gateway to a community of likeminded people. Drivers are able to offer instant or scheduled lifts to fellow passengers who are heading in a similar direction.",
      img: tagoridebg,
      thumbImg:
        tagoride,
      additionalImage1: google,
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
      additionalLink1: apple,  // Add this line
      // additionalLink2: "https://apps.apple.com/in/app/tangoride-carpooling/id1449951780" ,
    },
    {
      name: "Safelet(safety band app)",
      desc:
        "Safelet , the company behind the Safelet Bracelet. Safelet allows you to notify your friends, family and the police where you are and that you need help.",
      img:
        safeletbg,
      thumbImg:
        safelet,
      additionalImage1: google,
      additionalImage2: apple,
      additionalLink1: "https://play.google.com/store/apps/details?id=com.safelet.android",  // Add this line
      additionalLink2: "https://apps.apple.com/in/app/safelet-safety-bracelet/id1079472866",
    },
    {
      name: "Carmudi SFA",
      desc:
        "The application facilitates the users to check in at assigned dealership, office, customer visits and visits to new dealerships. Other than allowing the user to check at specific locations the app also facilitates the following-Register filed visit activities precisely Set visits followup at assigned dealership.",
      img:
        carmudibg,
      thumbImg:
        carmudi,
      additionalImage1: google,
      additionalImage2: apple,
      additionalLink1: "https://play.google.com/store/apps/details?id=com.int.sfa.ph&hl=en-IN",  // Add this line
      // additionalLink2: "#" ,
    },
    // {
    //   name: "Hinow(Private Video Chat app)",
    //   desc:
    //   "Hinow is a wonderful online video chat app for you to chat with lots of talented Stars⭐ and new friends all over the world 🌐. One on One Video Chat, Random Video Chat and Text Chat connect with anyone from anywhere in the world with a single tap! It is a really easy and fast way to meet new people!",
    //   img:
    //     "http://localhost/ibswp-content/uploads/2024/01/Hinow.png",
    //   thumbImg:
    //     "http://localhost/ibswp-content/uploads/2024/01/Group-3021.png",
    //     additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
    //   // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
    //     additionalLink1: "https://play.google.com/store/apps/details?id=tv.hinow.mobile&hl=en&gl=US",  // Add this line
    //   // additionalLink2: "#" ,
    // },
    /* {
      name: "Purplle(Online Beauty Shopping)",
      desc:
      "The Purplle app is a very pocket-friendly app with Beauty  Cosmetic products having various top brands such as Mamaearth, Good Vibes, Lakme, Maybelline, L’Oreal, Sugar many more.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Purplle.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3022.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.manash.purplle&hl=en_IN&gl=US",  // Add this line
      // additionalLink2: "#" ,
    }, */
    {
      name: "u_paid_m(Billing app)",
      desc:
        "We are on a mission to lift 1.5 billion people above the poverty line by eradicating banking and payment processing payment charges amounting today to $23 per year. Businessmen, open an account with us in 3 easy steps. That is all it takes to start transacting with your customers or....",
      img:
        upaidbg,
      thumbImg:
        upaid,
      additionalImage1: google,
      additionalImage2: apple,
      // additionalLink1: "https://play.google.com/store/apps/details?id=com.u_paid_m",  // Add this line
      additionalLink2: "https://apps.apple.com/in/app/u-paid-m/id1601713203",
    },
  ];

  const activeInfo = list[activeIndex];

  const itemPosition = list.map((_, key) => {
    const deg = (360 / list.length) * key - 90;
    const radius = "8rem";
    return `rotate(${deg}deg) translate(${radius}) rotate(${-deg}deg)`;
  });

  const handleScroll = (event) => {
    if (isScrolling) return;
    const direction = event.deltaY > 0 ? 1 : -1;
    let newIndex = activeIndex + direction;
    if (newIndex < 0) newIndex = list.length - 1;
    if (newIndex >= list.length) newIndex = 0;
    setActiveIndex(newIndex);
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  // useEffect(() => {
  //   window.addEventListener('wheel', handleScroll);
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, [activeIndex, isScrolling]);

  useEffect(() => {
    if (location.pathname === '/Mobile-Portfolio') {
      // Add class when the component is mounted on the specified route
      document.body.classList.add('bodys-1');
    }

    const handleScrollEvent = (event) => {
      handleScroll(event); // Call your existing scroll function
    };

    window.addEventListener('wheel', handleScrollEvent);

    return () => {
      if (location.pathname === '/Mobile-Portfolio') {
        // Remove class when the component is unmounted
        document.body.classList.remove('bodys-1');
      }
      window.removeEventListener('wheel', handleScrollEvent);
    };
  }, [location.pathname, activeIndex, isScrolling]);
  return (
    // <div id="app">
    <> <div className="container-fluid" id="portolio">
      <Link to="/mobile-inner"><img src={viewallbutton} alt="" id="viewallbutton" /></Link>
      <div className="row">
        <div className="col-md-6">
          <div
            className="hero-bg"
            style={{ backgroundImage: `url(${activeInfo.img})` }}
            key={activeIndex}
          ></div>
          <div className="hero-title" key={activeIndex}></div>
          <div className="round">
            {list.map((el, key) => (
              <div
                className={`item ${activeIndex === key ? 'active' : ''}`}
                onClick={() => setActiveIndex(key)}
                key={key}
                style={{ transform: itemPosition[key] }}
              >
                <div className="inner">
                  <img src={el.thumbImg} alt="" />
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="col-md-6">  {activeInfo && (
          <div className="hero-info" key={activeIndex}>
            <div className="row">
              <div className="content">{activeInfo.name}</div>
            </div>
            <div className="row">
              <div className="content desc">{activeInfo.desc}</div>
            </div>
            <div className="row appbtn" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    {activeInfo.additionalImage1 && (
                      <a href={activeInfo.additionalLink1} target="_blank" rel="noreferrer">
                        <img src={activeInfo.additionalImage1} alt="" />
                      </a>
                    )}

                  </div>
                  <div className="col-md-6">
                    {activeInfo.additionalImage2 && (
                      <a href={activeInfo.additionalLink2} target="_blank" rel="noreferrer">
                        <img src={activeInfo.additionalImage2} alt="" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}</div>
      </div>
    </div></>
    // </div>
  );
};

export default MobilePortfolioComp;
