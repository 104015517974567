import React from 'react'
import Footer from '../../Components/Footer/Footer'
import FooterUpper from '../../Components/FooterUpper/FooterUpper';
import '../Animation/Animation.css';
import Lines from '../../Components/Lines/Lines';
import animationvideo from '../../Assets/Images/animationvideo.mp4';
import Engaging from '../../Assets/Images/animation/Engaging.png';
import Interactive from '../../Assets/Images/animation/Interactive.png';
import EngagingSaaS from '../../Assets/Images/animation/EngagingSaaS.png';
import DynamicSocial from '../../Assets/Images/animation/DynamicSocial.png';
import Whiteboard from '../../Assets/Images/animation/Whiteboard.png';
import Broll from '../../Assets/Images/animation/B-roll.png';
import Promotional from '../../Assets/Images/animation/Promotional.png';
import lastanim from '../../Assets/Images/animation/lastanim.png'
import yutub1 from '../../Assets/Images/animation/yutub1.png'
import yutub2 from '../../Assets/Images/animation/yutub2.png'
import yutub3 from '../../Assets/Images/animation/yutub3.png'
import yutub4 from '../../Assets/Images/animation/yutub4.png'
import yutub5 from '../../Assets/Images/animation/yutub5.png'
import yutub6 from '../../Assets/Images/animation/yutub6.png'
import yutub7 from '../../Assets/Images/animation/yutub7.png'
import yutub8 from '../../Assets/Images/animation/yutub8.png'
import yutub9 from '../../Assets/Images/animation/yutub9.png'
import yutub10 from '../../Assets/Images/animation/yutub10.png'
import yutub11 from '../../Assets/Images/animation/yutub11.png'
import yutub12 from '../../Assets/Images/animation/yutub12.png'
export const Animation = () => {
    return (
        <div>
            <div className="animation-container">
                {/* Background Video */}
                <video className="ai-video" autoPlay muted >
                    <source src={animationvideo} type="video/mp4" />
                    Your browser does not support the video tag. Please upgrade to a modern browser.
                </video>

                {/* Black Overlay Layer */}
                <div className="ai-overlay"></div>

                {/* Centered Content */}
                <div className="animation-content">
                    <h1>"Bringing Your Ideas to Life with Stunning Animated Explainer Videos!"
                    </h1>
                    <p>Our Explainer Video Experts Creates Powerful And Engaging Videos To Boost Your Business</p>
                </div>
            </div>

            <div className="container animionsects">
                <div className="row aminsecro1">
                    <h2>Videos And Animtions</h2>
                    <Lines className='mb-5' />
                    <h3>"Transforming Ideas into Captivating Visual Stories, Frame by Frame!"</h3>
                    <p>"Our videos grab attention, engage viewers, and transform your message into a powerful tool<br></br> for driving customer conversions."</p>
                </div>
                <div className="row aminsecro2">
                    <div className="col-md-3">
                        <img src={Engaging} alt="" />
                        <h4>Engaging and Effective Animated Explainer Videos</h4>
                        <p>"Explainer videos are a versatile tool for marketing, education, and training, making complex ideas easy to understand and capturing attention in just moments."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={Interactive} alt="" />
                        <h4>Interactive and engaging E-Learning videos designed</h4>
                        <p>"Creating e-learning videos can be overwhelming, but we make it easy. Just provide us with an HD green screen video, and we'll take care of the rest, delivering high-quality, engaging content."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={EngagingSaaS} alt="" />
                        <h4>Engaging SaaS videos</h4>
                        <p>"SaaS videos effectively highlight the key features and benefits of your software, offering clear and engaging insights that are perfect for product launches, tutorials, and marketing campaigns."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={DynamicSocial} alt="" />
                        <h4>Dynamic Social Media Videos</h4>
                        <p>"We create a variety of video formats, including promotional content, product demos, behind-the-scenes footage, and customer testimonials, helping brands build a dynamic and shareable presence on social media."</p>
                    </div>
                </div>
                <div className="row aminsecro2 pt-3">
                    <div className="col-md-3">
                        <img src={Whiteboard} alt="" />
                        <h4>Whiteboard Videos That Simplify Your Message</h4>
                        <p>"Explainer videos are a versatile tool for marketing, education, and training, making complex ideas easy to understand and capturing attention in just moments."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={Broll} alt="" />
                        <h4>B-roll Explainer Videos that enhance storytelling and bring</h4>
                        <p>"B-roll explainer videos use supplementary footage to elevate storytelling, adding depth and visual interest to marketing campaigns, documentaries, and tutorials."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={Promotional} alt="" />
                        <h4>Promotional videos that inspire action and leave a lasting</h4>
                        <p>"Promotional videos are crafted to make a memorable impact, stir emotions, and inspire action, whether for product launches, brand awareness, or event promotions."</p>
                    </div>

                    <div className="col-md-3">
                        <img src={lastanim} alt="" />
                        <h4>Animated infographics that simplify and captivate your audience</h4>
                        <p>"Like static infographics, we tell your story with a blend of visuals and text, but with the added dynamic power of motion to keep your audience engaged."</p>
                    </div>
                </div>
            </div>

            <div className="container animionsects">
                <div className="row aminsecro1">
                    <h2>Portfolio</h2>
                    <Lines className='mb-5' />
                    <p>"Explainer videos are an innovative and effective way to showcase your product<br></br> and connect with your audience."</p>
                </div>
                <div className="row aminsecro2">
                    <div className="col-md-3">
                        <img src={ yutub1 } alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={ yutub2 } alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={ yutub3 } alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub4} alt="" />
                    </div>
                </div>
                <div className="row aminsecro2 pt-3">
                    <div className="col-md-3">
                        <img src={yutub5} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub6} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub7} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub8} alt="" />
                    </div>
                </div>

                <div className="row aminsecro2 pt-3">
                    <div className="col-md-3">
                        <img src={yutub9} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub10} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub11} alt="" />
                    </div>

                    <div className="col-md-3">
                        <img src={yutub12} alt="" />
                    </div>
                </div>
                <button className='conow'>Connect Now</button>
            </div>

            <div className="container-fluid bgforinmg">

            </div>

                <div className="container" id="footer">
                    <FooterUpper />
                    <Footer />
                </div>
        </div>
    )
}
