import React from 'react'
import '../Lines/Lines.css'

const Lines = () => {
  return (
    <div>
         <div className="hr1disp">
                  <center>
                    <div className="hr1"></div>
                    <div className="hr2"></div>
                  </center>
                </div>
    </div>
  )
}

export default Lines