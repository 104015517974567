import AiVideo from '../../Assets/Video/aivideo.mp4';
import '../AiPage/AiPage.css';
import Button from '../../Components/Button/Button';
import Floatingicons from '../../Components/Floatingicons/Floatingicons';
import React, { useState } from "react";
import Footer from '../../Components/Footer/Footer'
import FooterUpper from '../../Components/FooterUpper/FooterUpper';
// images ,,,.....
import logo1 from '../../Assets/Images/artificial.png'
import logo2 from '../../Assets/Images/product-development.png'
import logo3 from '../../Assets/Images/artificial-intelligence (1).png'
import logo4 from '../../Assets/Images/technology.png'
import logo5 from '../../Assets/Images/smartphone.png'
import logo6 from '../../Assets/Images/planning.png'
import reso8 from '../../Assets/Images/Reson8.png'
import acordnicn from '../../Assets/Images/Union2.png'
import bootsimg from '../../Assets/Images/bootsGroup.png'
import cricalaiimg from '../../Assets/Images/cricalai.png'
// import AiScroller from '../../Components/AiPageScroller/AiScroller';
import MobilePortfolioComp from '../../Components/MobilePortfolioComp/MobilePortfolioComp';
export const AiPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionItems = [
        {
            title: "Personalized AI Solutions",
            content:
                "Our holistic approach integrates all aspects of AI specifically to your custom business needs, from artificial intelligence consulting and development to deployment and maintenance.",
        },
        {
            title: "Compliance-First Approach",
            content: "We ensure adherence to regulatory standards to build trust and reliability.",
        },
        {
            title: "Innovation-Inclusive Culture",
            content:
                "Fostering an environment that values creativity and forward-thinking solutions.",
        },
        {
            title: "Proven Track Record",
            content:
                "Delivering consistent and measurable success in AI implementations.",
        },
        {
            title: "In-House AI Accelerator",
            content:
                "Equipped with cutting-edge tools and a skilled team to fast-track AI adoption.",
        },
    ];
    return (
        <div>
            {/* AI Page Content Section */}
            <div className="ai-container">
                {/* Background Video */}
                <video className="ai-video" autoPlay muted loop>
                    <source src={AiVideo} type="video/mp4" />
                    Your browser does not support the video tag. Please upgrade to a modern browser.
                </video>

                {/* Black Overlay Layer */}
                <div className="ai-overlay"></div>

                {/* Centered Content */}
                <div className="ai-content">
                    <h1>Harnessing the Power of Proven AI Solutions for Independent Growth</h1>
                    <p>Enhance your business with AI solutions that optimize processes, improve customer interactions, and foster innovation. Unlock new potential today!</p>
                    <div className='cntrbtn'><Button text="Consult Our AI Experts" /></div>
                </div>
            </div>

            {/* Second Section - Floating Icons */}
            <div className="container-fluid flotingaisecon">
                <div className="row">
                    <div className="col-md-12">
                        <div className='flotingwork'>
                            <h2>Artificial Intelligence services and solutions designed to help your business.</h2>
                            <div className='floatingbg'>
                                <Floatingicons />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container aisecon3">
                    <div className="row aisecon3s">
                        <div className="col-md-12">
                            <h2 className='lineaferbfr'>We have the right AI tools and expertise
                                <br />to support your goals.</h2>
                            <p>Discover our AI services, crafted to drive innovation and deliver real results. From consulting to development, we provide tailored AI solutions to meet the unique needs of any industry.</p>
                        </div>
                    </div>
                    <div className="row newmycdr">
                        <div className="col-md-12 mycdr">
                            <div class="cards-container">
                                <div class="cards">
                                    <h3>Next-generation<br></br> digital evolution</h3>
                                    <p>Next-gen digital transformation integrates advanced AI, machine learning, and analytics to revolutionize business operations. Unlike conventional methods, it enables rapid, seamless change that aligns with existing workflows and organizational culture, reducing the need for costly, time consuming change management.</p>
                                    <div class="icons"><img src={logo1} alt="" /></div>
                                </div>
                                <div class="cards">
                                    <h3>Research and<br></br> Development Innovation</h3>
                                    <p>At Future Works, our R&D team is at the forefront of innovation, driving advancements in Industry 4.0, climate tech, and AI. We focus on creating cutting-edge software solutions and evaluating emerging trends to foster growth across sectors such as real estate, energy, aerospace, manufacturing, and more.</p>
                                    <div class="icons"><img src={logo2} alt="" /></div>
                                </div>
                                <div class="cards">
                                    <h3>AI-Powered Custom<br></br> Software</h3>
                                    <p>Our AI-powered solutions combine predictive analytics with seamless integrations to keep businesses ahead.</p>
                                    <div class="icons"><img src={logo3} alt="" /></div>
                                </div>
                                <div class="cards">
                                    <h3>Technology Solutions</h3>
                                    <p>We specialize in partnering with innovative companies in technical sectors, where we believe the greatest potential lies in advancing with AI-driven custom software solutions.</p>
                                    <div class="icons"><img src={logo4} alt="" /></div>
                                </div>
                                <div class="cards">
                                    <h3>Generative AI</h3>
                                    <p>Unlock the potential of our generative Al services to innovate and create custom Al solutions like ChaiGPT, Sora, AMIE, and others. Whether generating text, images, and videos or building unique Gen Al-powered applications, we empower you to explore new creative frontiers and redefine possibilities in your industry.</p>
                                    <div class="icons"><img src={logo5} alt="" /></div>
                                </div>
                                <div class="cards">
                                    <h3>Brand Strategy Execution</h3>
                                    <p>At Future Works, we integrate tactical branding with cutting edge transformation to elevate your brand. Our services take your brand from idea to execution, ensuring seamless representation across all digital platforms, including full-stack development, website creation, and digital product design.</p>
                                    <div class="icons"><img src={logo6} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid newrowais">
                <div className="row newrowai">
                    <div className="col-md-12"></div>
                </div>
                <div className="container appsection">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <MobilePortfolioComp /> */}
                        </div>
                    </div>
                    <div className="row asscordinsx">
                        <div className="col-md-12 ai-page-container">
                            {/* Left Section */}
                            <div className="left-section">
                                <div className="icon-section">
                                    <img src={acordnicn} alt="" />
                                </div>
                                <div className="text-section">
                                    <h2>
                                        Interestbud Solutions is your ideal AI consulting and
                                        development partner.
                                    </h2>
                                    <p>
                                        We offer customized solutions, deep industry expertise,
                                        cutting-edge technology, and reliable support to drive
                                        innovation and efficiency for your business.
                                    </p>
                                    <blockquote>
                                        "We're your ideal AI partner, delivering tailored
                                        solutions with unmatched expertise and a commitment to
                                        excellence for exceptional results."
                                    </blockquote>
                                </div>
                            </div>

                            {/* Right Section */}
                            <div className="right-section">
                                {accordionItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`accordion-item ${activeIndex === index ? "active" : "inactive"
                                            }`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <div className="accordion-header">
                                            <h3>{item.title}</h3>
                                            <span className="accordion-icon">
                                                {activeIndex === index ? "▼" : "▶"}
                                            </span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row aisecon3s newaisecon3s">
                        <div className="col-md-12">
                            <h2 className='lineaferbfrx'>We boost efficiency, innovation, and growth
                                <br />manufacturing through AI solutions.</h2>
                            <p>Our AI services transform businesses by boosting innovation, efficiency, and growth. We offer tailored solutions across industries like healthcare, finance, retail, real estate, and hospitality, helping businesses stay competitive with the latest AI technologies.</p>
                            <div className='botimgs'>
                                <img src={bootsimg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row aisecon3s newaisecon3s">
                        <div className="col-md-12">
                            <h2 className='lineaferbfrv'>AI Frameworks for Developing
                                <br />Intelligent Models</h2>
                            <p>We design custom AI solutions using a tailored technology stack, combining cutting-edge machine learning frameworks and cloud platforms to deliver scalable, stable, and efficient AI-driven results.</p>
                        </div>
                        <div className='botimgsx'>
                            <img src={cricalaiimg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bgcsd'>
                <div className="container" id="footer">
                    <FooterUpper />
                    <Footer />
                </div>
            </div>

        </div>
    );
};
